<script>
import Layout from '@/router/layouts/main';
import { api } from '@/state/services';
import { mapState } from 'vuex';
import Stat from '@/components/widgets/stat';

export default {
  locales: {
    pt: {
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Points': 'Pontos',
      'STATUS': 'STATUS',
      'QUALIFIED': 'QUALIFICADO',
      'UNQUALIFIED': 'NÃO QUALIFICADO',
      'LEFT TEAM': 'EQUIPE ESQUERDA',
      'RIGHT TEAM': 'EQUIPE DIREITA',
      'PERCENT': 'PORCENTAGEM',
      'BONUS FORECAST': 'PREVISÃO DE BÔNUS',
      'Referral Link': 'Link de Indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Points': 'Puntos',
      'STATUS': 'ESTADO',
      'QUALIFIED': 'CALIFICADO',
      'UNQUALIFIED': 'NO CUALIFICADO',
      'LEFT TEAM': 'EQUIPO IZQUIERDA',
      'RIGHT TEAM': 'EQUIPO DERECHA',
      'PERCENT': 'POR CIENTO',
      'BONUS FORECAST': 'PREVISIÓN DE BONIFICACIÓN',
      'Referral Link': 'Link de Patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, Stat },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      time: {
        date: "",
        hour: "",
        zone: "",
      },

      balance: {
        total: "-- -,--",
        blocked: "-- -,--",
        available: "-- -,--",
      },

      statData: null,
      wallets: null,
    };
  },
  methods: {
    getTime() {
      api
        .get('time')
        .then(response => {
          if (response.data.status=='success') {
            this.time.date = response.data.date
            this.time.hour = response.data.hour
            this.time.zone = response.data.zone
          }
        })
    },
    getBalance() {
      api
        .get('wallet/balance/cash')
        .then(response => {
          if (response.data.status=='success') {
            this.balance.total = response.data.total;
            this.balance.blocked = response.data.blocked;
            this.balance.available = response.data.available;
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Saldo Total",
          value: this.balance.total,
        },
        {
          title: "Saldo Bloqueado",
          value: this.balance.blocked,
        },
        {
          title: "Saldo Disponível",
          value: this.balance.available,
        },
      ]
    }
  },
  mounted() {
    this.getTime()
    this.getStatData()
    this.getBalance()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Dashboard') }}</h4>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6 col-md-3 mb-2 card-h-fix">
        <router-link tag="a" to="/invoices/create" class="btn btn-default btn-block p-3">
          <h4 class="m-0 text-white" style="line-height: 20px;">Enviar Nota Fiscal</h4>
        </router-link>
      </div>
      <div class="col-6 col-md-3 mb-2 card-h-fix">
        <router-link tag="a" to="/invoices" class="btn btn-default btn-block p-3">
          <h4 class="m-0 text-white" style="line-height: 20px;">Minhas Notas</h4>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <template v-if="account.user.indication">
          <div class="mt-1">
            <i class="bx bx-link font-size-20 mr-1"></i>
          </div>
          <h5 class="m-0 align-items-center">
            {{ t("Referral Link") }}
          </h5>
          <div class="mt-1 ml-3">
            <ShareNetwork network="whatsapp" url="" :title="'https://app.notaquerende.org.br/' + account.user.indication">
              <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
            </ShareNetwork>
          </div>
          <div class="mt-1 ml-2">
            <button class="btn btn-link p-0 m-0" v-clipboard:copy="'https://app.notaquerende.org.br/' + account.user.indication
              " v-on:click="$noty.success('O link foi copiado!')">
              <i class="bx bx-copy font-size-20 text-dark"></i>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="py-2">
            <h5 class="mb-0">
              <i class="bx bx-link mr-1"></i> {{ t('Referral Link') }}
            </h5>
            <router-link class="ml-4" tag="a" to="/account/indication">
              Você ainda não possui um link de indicação, <u>clique aqui</u> para criar.
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>
